import React from 'react'
import { Link } from 'gatsby'

import style from './Navigation.module.css'

class Navigation extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }

  render() {
    return (
      <>
        <button
          className={style.menu}
          onClick={() =>
            this.setState((state, props) => ({ isOpen: !state.isOpen }))
          }
        >
          {this.state.isOpen ? 'X' : '☰'}
        </button>
        <div
          className={`${style.navigation} ${this.state.isOpen ? style.in : ''}`}
        >
          <Link to="/">Home</Link>
          <Link to="/events">Upcoming Events</Link>
          <Link to="/past_events">Past Events</Link>
          <a
            href="https://docs.google.com/forms/d/1mxMOSCnrz_o7qmT1WqvCoy1c2LZzhopuT794x9DLmOg/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
          <Link to="/contact">Contact</Link>
          {/* <Link to="/profiles">Profiles</Link>
      <Link to="/rules">Rules</Link> */}
          <a
            href="https://yogya.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Yogya
          </a>
        </div>
      </>
    )
  }
}

export default Navigation

import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import Navigation from '../components/Navigation'
import style from './register.module.css'

const Register = ({ data }) => {
  return (
    <Layout>
      <Navigation />
      <div className={style.container} />
    </Layout>
  )
}

export default Register
